<template>
  <div>
    <b-sidebar
      id="add-kas-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="addGajiKaryawanSidebar"
      right
      no-header
      backdrop
      @hidden="(val) => changeValue(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ gajiKaryawanLocal.id > 0 ? 'Bayar Gaji Karyawan' : 'Add Gaji Karyawan' }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <validation-observer
          ref="simpleRules"
          class="px-2"
        >
          <b-form>
            <b-row class="px-2">
              <b-col md="12">
                <b-form-group
                  label="Nama Karyawan"
                  label-for="nama-karyawan-gaji-karyawan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Karyawan"
                    rules="required"
                  >
                    <b-form-input
                      id="nama-karyawan-gaji-karyawan"
                      v-model="gajiKaryawanLocal.karyawan.nama"
                      :disabled="gajiKaryawanLocal.id > 0"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nama Karyawan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Nama Gaji"
                  label-for="nama-karyawan-gaji-karyawan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Gaji"
                    rules="required"
                  >
                    <b-form-input
                      id="nama-karyawan-gaji-karyawan"
                      v-model="gajiKaryawanLocal.nama_gaji"
                      :disabled="gajiKaryawanLocal.id > 0"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nama Gaji"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Jumlah"
                  label-for="jumlah-gaji-karyawan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah"
                    rules="required"
                  >
                    <b-form-input
                      id="jumlah-gaji-karyawan"
                      v-model="gajiKaryawanLocal.nominal_gaji"
                      :disabled="gajiKaryawanLocal.id > 0"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Jumlah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col md="12">
                <b-form-group
                  label="Tanggal"
                  label-for="tanggal-gaji-karyawan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="tanggal"
                      v-model="gajiKaryawanLocal.tanggal"
                      :state="errors.length > 0 ? false:null"
                      class="mb-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col
                v-show="gajiKaryawanLocal.id > 0"
                md="12"
              >
                <b-form-group
                  label="Upload Bukti Pembayaran Gaji"
                  label-for="foto-bukti"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Upload Bukti Pembayaran Gaji"
                    :rules="gajiKaryawanLocal.id > 0 ? 'required' : ''"
                  >
                    <b-form-file
                      id="foto-bukti"
                      v-model="gajiKaryawanLocal.foto_nota"
                      accept="image/*"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Pilih File"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submit()"
                >
                  Submit
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="changeValue(false)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BCol,
  BRow, BSidebar, BFormFile,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCol,
    BRow,
    BSidebar,
    BFormFile,
  },
  model: {
    prop: 'addGajiKaryawanSidebar',
  },
  props: {
    addGajiKaryawanSidebar: {
      type: Boolean,
      required: true,
    },
    gajiKaryawan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      gajiKaryawanLocal: {
        id: null,
        nama: null,
        nama_gaji: null,
        nominal_gaji: null,
        tanggal: null,
        foto_nota: null,
        karyawan: {
          nama: null,
        },
      },
      optionsSupir: [],
      optionsBis: [],
    }
  },
  watch: {
    gajiKaryawan() {
      this.resetTaskLocal()
    },
  },
  mounted() {
    //
  },
  methods: {
    resetTaskLocal() {
      this.gajiKaryawanLocal = {
        id: this.gajiKaryawan.id,
        nama: this.gajiKaryawan.nama,
        nama_gaji: this.gajiKaryawan.nama_gaji,
        nominal_gaji: this.gajiKaryawan.nominal_gaji,
        tanggal: this.gajiKaryawan.tanggal,
        karyawan: this.gajiKaryawan.karyawan,
      }
    },
    changeValue() {
      const data = {
        id: null,
        nama: null,
        nama_gaji: null,
        nominal_gaji: null,
        foto_nota: null,
        karyawan: {
          nama: null,
        },
      }
      this.$emit('gaji-karyawan-sidebar-change', data, false)
    },
    submit() {
      if (this.gajiKaryawanLocal.id > 0) {
        this.$emit('submit-data-gaji-karyawan', this.gajiKaryawanLocal)
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$emit('submit-data-gaji-karyawan', this.gajiKaryawanLocal)
          }
        })
      }
    },
  },
}
</script>
<style>
.b-sidebar-body {
  overflow: hidden !important;
}
</style>
