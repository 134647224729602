var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"add-kas-sidebar","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.addGajiKaryawanSidebar,"right":"","no-header":"","backdrop":""},on:{"hidden":function (val) { return _vm.changeValue(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.gajiKaryawanLocal.id > 0 ? 'Bayar Gaji Karyawan' : 'Add Gaji Karyawan')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules",staticClass:"px-2"},[_c('b-form',[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Karyawan","label-for":"nama-karyawan-gaji-karyawan"}},[_c('validation-provider',{attrs:{"name":"Nama Karyawan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nama-karyawan-gaji-karyawan","disabled":_vm.gajiKaryawanLocal.id > 0,"state":errors.length > 0 ? false:null,"placeholder":"Nama Karyawan"},model:{value:(_vm.gajiKaryawanLocal.karyawan.nama),callback:function ($$v) {_vm.$set(_vm.gajiKaryawanLocal.karyawan, "nama", $$v)},expression:"gajiKaryawanLocal.karyawan.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Gaji","label-for":"nama-karyawan-gaji-karyawan"}},[_c('validation-provider',{attrs:{"name":"Nama Gaji","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nama-karyawan-gaji-karyawan","disabled":_vm.gajiKaryawanLocal.id > 0,"state":errors.length > 0 ? false:null,"placeholder":"Nama Gaji"},model:{value:(_vm.gajiKaryawanLocal.nama_gaji),callback:function ($$v) {_vm.$set(_vm.gajiKaryawanLocal, "nama_gaji", $$v)},expression:"gajiKaryawanLocal.nama_gaji"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jumlah","label-for":"jumlah-gaji-karyawan"}},[_c('validation-provider',{attrs:{"name":"Jumlah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"jumlah-gaji-karyawan","disabled":_vm.gajiKaryawanLocal.id > 0,"type":"number","state":errors.length > 0 ? false:null,"placeholder":"Jumlah"},model:{value:(_vm.gajiKaryawanLocal.nominal_gaji),callback:function ($$v) {_vm.$set(_vm.gajiKaryawanLocal, "nominal_gaji", $$v)},expression:"gajiKaryawanLocal.nominal_gaji"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.gajiKaryawanLocal.id > 0),expression:"gajiKaryawanLocal.id > 0"}],attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Upload Bukti Pembayaran Gaji","label-for":"foto-bukti"}},[_c('validation-provider',{attrs:{"name":"Upload Bukti Pembayaran Gaji","rules":_vm.gajiKaryawanLocal.id > 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"foto-bukti","accept":"image/*","state":errors.length > 0 ? false:null,"placeholder":"Pilih File"},model:{value:(_vm.gajiKaryawanLocal.foto_nota),callback:function ($$v) {_vm.$set(_vm.gajiKaryawanLocal, "foto_nota", $$v)},expression:"gajiKaryawanLocal.foto_nota"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeValue(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }